<template>
  <div>
    <strong>Usuario: {{ props.row.usuario }}</strong><br>
    <b-tag>ID Operación: {{ props.row.transactionid }}</b-tag>
    <br>
    <small>Fecha: {{ formatDate(props.row.fecha, props.row.hora) }}</small><br>
    <small>Score configurado: {{ (props.row.confidence * 100).toFixed(2) }}%</small><br>
    <small>Score total: {{ (props.row.confidenceconfigured * 100).toFixed(2) }}%</small><br>
    <small>Código Respuesta: {{ props.row.codigo_respuesta }}</small><br>
    <small>Descripción Respuesta: {{ props.row.descripcion_respuesta }}</small><br>
    <b-tag :type="getTagFaceText(props.row.confidenceconfigured, props.row.confidence)">
      Estado: {{ getStatusFaceText(props.row.confidenceconfigured, props.row.confidence) }}
    </b-tag>
  </div>
</template>

<script>
import {
  dateFormatting, getStatusFace, getTagFace
} from "../../../assets/config/utilities";

export default {
  name: "FirstCardDetail",
  props: [
    'props'
  ],
  methods: {
    formatDate(date) {
      return dateFormatting(date);
    },
    getTagFaceText(scoreConfigured, scoreTotal) {
      return getTagFace(scoreConfigured, scoreTotal);
    },
    getStatusFaceText(scoreConfigured, scoreTotal) {
      return getStatusFace(scoreConfigured, scoreTotal);
    },
  }
}
</script>

<style scoped>

</style>